<template>
  <div class="portrait-page main">
    <div class="bg-black">
      <div class="ss-container">
        <h6 class="section-title">Caricature Portrait</h6>
        <p class="section-desc">客訂似顏繪</p>
      </div>
    </div>
    <div class="bg-black black-section">
      <ListTemplate :data="portraits" category="portrait"></ListTemplate>
    </div>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import Portraits from "@/data/portraits.js";
export default {
  name: "Character",
  components: {
    ListTemplate
  },
  data() {
    return {
      portraits: Portraits
    };
  }
};
</script>

<style lang="sass" scoped>
.portrait-page
  padding-top: 130px
  background-color: #111111
  +deviceWidth()
    padding-top: 50px
.black-section
  padding-bottom: 48px
  +deviceWidth()
    padding-bottom: 30px
</style>
